import { useMemo } from "react";
import { Box, alpha } from "@mui/material";
import {
  DocumentPage,
  DocumentParagraph,
  DocumentSubtitle,
  DocumentTable,
  DocumentTitle,
  DocumentOrderedList,
  SignatureCard,
  SignHereCard,
} from "components";
import { useStyles } from "hooks";
import {
  CSSGenerator,
  EventType,
  PrecontractDocumentData,
  Setting,
} from "interfaces";
import {
  divideDataInTableBreakpoints,
  formatSignDate,
  formattedPrecontractDates,
  generateRecordItems,
  getEventMainText,
  getResponsiveSize,
  injectData,
} from "utils";
import { DocumentComponentType, TableBreakpoints, EventsPages } from ".";

// WARNING: only considering cell fontSize = getResponsiveSize(14)
const TABLE_BREAKPOINTS: TableBreakpoints = {
  firstPage: 5,
  fullPage: 7,
  firstPageWithBottomText: 3,
};
// Nuevas condiciones para contenidos... 4 contenidos primera página...
const TABLE_BREAKPOINTS_CONTENT_DATA: TableBreakpoints = {
  firstPage: 4,
  fullPage: 7,
  firstPageWithBottomText: 3,
};

const COURSES_TABLE_HEADERS = [
  "CODIGO SENCE",
  "NOMBRE CURSO",
  "OTEC",
  "LUGAR EJECUCION",
  "HORARIO",
  "DURACION EN HORAS",
  "FECHA INICIO/TERMINO",
];
const TASKS_TABLE_HEADERS = [
  "FUNCIONES Y/O TAREAS",
  "CONTENIDOS TEMATICOS",
  "N° DE HORAS",
];

export const PrecontractDocument: DocumentComponentType = ({
  data,
  openDialog,
  signed,
  settings,
  template,
  user,
}) => {
  const { title, firstParagraph, articles, observations } = (
    settings as unknown as Setting
  )?.precontractDocumentText ?? {
    articles: [],
    observations: [],
  };
  const styles = useStyles(generateStyles);

  const parsedData = useMemo(
    () => generatePrecontractReplaceableData(data.precontractData),
    [data],
  );

  //if (data){
  //
  //  console.log(data?.precontractData?.courses);
  //}

  const coursesParsedData = useMemo(() => {
    return (
      data.precontractData?.courses.map((course) => ({
        senceCode: course.senceCode,
        name: course.name,
        otecName: course.otec.name,
        location: course.location,
        schedule: course.schedule,
        duration: course.duration,
        period: formattedPrecontractDates(
          new Date(course.startDate),
          new Date(course.endDate),
        ),
      })) ?? []
    );
  }, [data]);

  const coursesParsedContent = useMemo(() => {
    return (
      data.precontractData?.courses.map((course) => ({
        functions: course.functions || '-',
        content: course.content || '-',
        functionshours: course.functionshours || '-',
      })) ?? []
    );
  }, [data]);

  const coursesParsedDataBp = useMemo(
    //() => divideDataInTableBreakpoints(testCoursesParsedData, TABLE_BREAKPOINTS),
    () => divideDataInTableBreakpoints(coursesParsedData, TABLE_BREAKPOINTS),
    [coursesParsedData],
  );

  // Se agrega nuevos parametros de contenidos...
  const parsedContentDataBp = useMemo(
    //() => divideDataInTableBreakpoints(coursesParsedContent, TABLE_BREAKPOINTS),
    () => divideDataInTableBreakpoints(coursesParsedContent, TABLE_BREAKPOINTS_CONTENT_DATA),
    [coursesParsedContent],
  );

  const parsedEvents = useMemo(
    () =>
      data.document
        ? [
            ...generateRecordItems(data.document, data.document.events),
            {
              mainText: getEventMainText(
                {
                  type: EventType.SIGN,
                  personName: data?.precontractData?.participant.name ?? "",
                  personEmail: data?.precontractData?.participant.email ?? "",
                },
                data?.document,
              ),
              date: formatSignDate(new Date()),
              eventType: EventType.SIGN,
            },
          ]
        : [],
    [data],
  );

  return (
    <>
      <DocumentPage sx={styles.root}>
        <DocumentTitle text={title} />
        <DocumentParagraph
          text={injectData(firstParagraph, parsedData, !template)}
        />
        <DocumentOrderedList
          texts={articles.slice(0, 1).map((a) => injectData(a, parsedData))}
        />
        <DocumentTable
          headers={COURSES_TABLE_HEADERS}
          rows={coursesParsedDataBp.firstPage ?? [{ blank: "a" }]}
          cellStyle={{ fontSize: getResponsiveSize(14) }}
        />
        {/* If no remaining, then proceed to add next articles */}
        {!coursesParsedDataBp.remaining && (
          <DocumentOrderedList
            texts={articles.slice(1, 3).map((a) => injectData(a, parsedData))}
            startAt={1}
          />
        )}
      </DocumentPage>
      {/* Remaining could be an array of arrays, the next code will map a DocumentPage component until no arrays left */}
      {coursesParsedDataBp.remaining?.map((courses, idx) => (
        <DocumentPage key={`remainingCoursesPage-${idx}`}>
          <DocumentTable
            headers={COURSES_TABLE_HEADERS}
            rows={courses}
            cellStyle={{ fontSize: getResponsiveSize(14) }}
          />
        </DocumentPage>
      ))}
      {/* Once all DocumentPages are created, the next code will check if remaining object existed, if so, it will create the next articles */}
      <DocumentPage>
        {coursesParsedDataBp.remaining && (
          <DocumentOrderedList
            texts={articles.slice(1, 3).map((a) => injectData(a, parsedData))}
            startAt={1}
          />
        )}
        <DocumentOrderedList
          texts={articles.slice(3, 5).map((a) => injectData(a, parsedData))}
          startAt={3}
        />
      </DocumentPage>

      <DocumentPage>
        <DocumentOrderedList
          texts={articles.slice(5, 6).map((a) => injectData(a, parsedData))}
          startAt={5}
        />
        <DocumentTable
          headers={TASKS_TABLE_HEADERS}
          rows={parsedContentDataBp.firstPage}
          cellStyle={{ fontSize: getResponsiveSize(12) }} // Se achica letra..
          // useCourseContentLineBreaks={false}
        />        
      </DocumentPage>

      {parsedContentDataBp.remaining?.map((content, idx) => (
        <DocumentPage key={`remainingCoursesPage-${idx}`}>
          <DocumentTable
            headers={TASKS_TABLE_HEADERS}
            rows={content}
            cellStyle={{ fontSize: getResponsiveSize(12) }} // Se achica letra..
          />
        </DocumentPage>
      ))}

      <DocumentPage>
        <DocumentOrderedList
          texts={articles.slice(6).map((a) => injectData(a, parsedData))}
          startAt={6}
        />
        <DocumentParagraph text="Para constancia, firman:" />
        <Box sx={styles.signatureSectionContainer}>
          {template ? (
            <>
              <SignHereCard
                name={user?.name ?? ""}
                rut={user?.rut ?? ""}
                email={user?.email ?? ""}
                companyname={parsedData["company-name"] as string}
                companyrut={parsedData["company-rut"] as string}
                role=""
                onClick={openDialog}
              />
              <SignatureCard
                name={"____________"}
                rut={"____________"}
                role="Capacitado(a)"
              />
            </>
          ) : (
            <>
              <SignatureCard
                name={parsedData["company-representativeName"] as string}
                rut={parsedData["company-representativeRut"] as string}
                email={parsedData["company-representativeEmail"] as string}
                role=""
                precontractlegal={true}
                companyname={parsedData["company-name"] as string}
                companyrut={parsedData["company-rut"] as string}
              />
              {signed ? (
                <SignatureCard
                  name={parsedData["participant-name"] as string}
                  rut={parsedData["participant-rut"] as string}
                  email={parsedData["participant-email"] as string}
                  phone={parsedData["participant-phone"] as string}
                  role="Capacitado(a)"
                />
              ) : (
                <SignHereCard
                  name={parsedData["participant-name"] as string}
                  rut={parsedData["participant-rut"] as string}
                  email={parsedData["participant-email"] as string}
                  phone={parsedData["participant-phone"] as string}
                  role="Capacitado(a)"
                  onClick={openDialog}
                />
              )}
            </>
          )}
        </Box>
        </DocumentPage>
        
        
      <DocumentPage>
        <DocumentSubtitle text="OBSERVACIONES:" sx={styles.observations} />
        <DocumentOrderedList
          texts={observations.map((o) => injectData(o, parsedData))}
          type="A"
          sx={{ marginTop: "20px" }} // ajsute para algunos modelos...se corre el texto y se traslapa...
        />
      </DocumentPage>
      {signed && data.document && (
        <EventsPages items={parsedEvents} document={data.document} />
      )}
    </>
  );
};

const generatePrecontractReplaceableData = (
  data?: PrecontractDocumentData,
): Record<string, string | number> => {
  if (!data) return {};
  return {
    signDate: data.signDate,
    "participant-city": data.participant.city,
    "participant-name": data.participant.name,
    "participant-nationality": data.participant.nationality,
    "participant-rut": data.participant.rut,
    "participant-age": data.participant.age,
    "participant-birthdate": data.participant.birthdate,
    "participant-address": data.participant.address,
    "participant-commune": data.participant.commune,
    "participant-region": data.participant.region,
    "participant-email": data.participant.email,
    "participant-phone": data.participant.phone,
    "company-name": data.company.name,
    "company-rut": data.company.rut,
    "company-representativeName": data.companyLegalRepresentative.name,
    "company-representativeRut": data.companyLegalRepresentative.rut,
    "company-representativeEmail": data.companyLegalRepresentative.email,
    //"company-address": data.company.address,
    "company-address": data.courses[0].adressSucCompany || '-',
    "company-city": data.company.city,
    "company-region": data.company.region,
    processCoursesTotalHours: data.processCoursesTotalHours,
    processStartDate: data.processStartDate,
    processFinishDate: data.processFinishDate,
    processDurationDays: data.processDurationDays,
    processVigencyFulldate: data.processVigencyDate,
  };
};

const generateStyles: CSSGenerator = (theme) => ({
  observations: {
    textAlign: "left",
    mb: "-20px",
  },
  secondPage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  upperContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: getResponsiveSize(92),
  },
  signatureContainer: {
    display: "flex",
    flexDirection: "column",
    gap: getResponsiveSize(21),
  },
  signatureBottomText: {
    color: alpha(theme.palette.common.black, 0.54),
  },
  signatureSectionContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
