import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Divider,
  Theme,
  Typography,
  Stack,
  Box,
  IconButton,
} from "@mui/material";
import { MailOutline, Clear, Group } from "@mui/icons-material";
import Fuse from "fuse.js";
import { map, sortBy, debounce } from "lodash";
import {
  useCourseDetails,
  uploadManualDocument,
  sendParticipantsReminders,
  sendCustomReminders,
  forceDocumentValidation,
  sendInternalMassReminder,
} from "api";
import {
  FeedbackContext,
  DocumentDialogContext,
  MessageContext,
  AppContext,
} from "contexts";
import {
  useAvailableDocumentActions,
  useStyles,
  useCanInteractWithParticipantDocument,
} from "hooks";
import {
  CSSGenerator,
  DocumentParticipantInfo,
  Permission,
  DocumentType,
  EmailSubject,
  DocumentStatus,
  EmailForm,
  DocumentShortNames,
  DocumentCompanyStatus,
  AppRoutes,
  DocumentParticipantInfoData,
} from "interfaces";
import {
  CustomTable,
  TableCellType,
  AccordionInfo,
  SearchBar,
  TableColumnData,
  CustomButton,
  MassiveReminderDialog,
  Restricted,
  CellIconData,
  DownloadButton,
} from "components";
import {
  parseDJOParticipantsData,
  getDocumentIconButtonData,
  getUploadDialogTitle,
  getValidationDialogTitle,
  DocumentActions,
  getMessageDialogParams,
  formatCourseRangeDates,
  ParsedCourseDetailsCSVInfo,
  parseCourseDetailsInfoToCSV,
  parseMultipleEmails,
} from "utils";

type Props = {
  columnDefs: TableColumnData[];
  documentType: DocumentType;
  sencenet: string;
  route: AppRoutes;
};

const DEBOUNCE_TIME = 600; // ms

export const DJODetailsPage: React.FC<Props> = ({
  columnDefs,
  documentType,
  sencenet,
  route,
}) => {
  /* CONTEXTS */
  const { isAllowedTo, user } = useContext(AppContext);
  const { toggleLoader, showSnackbar } = useContext(FeedbackContext);
  const { openMessageDialog, openInformationDialog } =
    useContext(MessageContext);
  const {
    openValidationDialog,
    openUploadDialog,
    closeValidationDialog,
    closeUploadDialog,
  } = useContext(DocumentDialogContext);

  /* HOOKS */
  const styles = useStyles(generateStyles);
  const { course, loading } = useCourseDetails({
    sencenet,
    documentType,
  });
  const getDocumentActions = useAvailableDocumentActions();
  const history = useHistory();
  const canInteractDocument = useCanInteractWithParticipantDocument();

  /* STATES AND MEMOS */
  const [massiveReminderDialogOpen, setMassiveReminderDialogOpen] =
    useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");

  const participantsData = useMemo(() => {
    if (course?.documentParticipantInfo) {
      return parseDJOParticipantsData(
        course?.documentParticipantInfo,
        history,
      );
    }
    return [];
  }, [course?.documentParticipantInfo, history]);

  const [participantsTableRawData, setParticipantsTableRawData] =
    useState<DocumentParticipantInfoData[]>(participantsData);
  const pageName = useMemo(() => `[${documentType}Page]`, [documentType]);

  const fuse = useMemo(() => {
    const data = participantsData.map((p) => ({
      name: p.name,
      rut: p.rut,
      email: p.email,
    }));
    const index = Fuse.createIndex(["name", "rut", "email"], participantsData);
    return new Fuse(
      data,
      {
        threshold: 0.4,
        isCaseSensitive: false,
        keys: ["name", "rut", "email"],
      },
      index,
    );
  }, [participantsData]);

  // Variable to determine if massive reminder should be sent to responsable
  const massiveReminderToResponsable: boolean = useMemo(
    () => isAllowedTo(Permission.CAN_SEND_RESPONSABLE_MAIL),
    [isAllowedTo],
  );

  const pendingManagementParticipants = useMemo(
    () =>
      sortBy(
        course?.documentParticipantInfo.filter((dp) =>
          massiveReminderToResponsable
            ? [
                DocumentStatus.PENDING,
                DocumentStatus.CORRECTION_REQUESTED,
              ].includes(dp.mainSignerStatus)
            : !dp.participant.hasMissingData &&
              dp.mainSignerStatus === DocumentStatus.PENDING,
        ),
        "mainSignerStatus",
      ),
    [course?.documentParticipantInfo, massiveReminderToResponsable],
  );

  const dialogInfoData = useMemo(
    () => ({
      courseName: course?.name ?? "",
      sencenet: course?.sencenet ?? "",
      sence: course?.senceCode ?? "",
      dates: formatCourseRangeDates(course),
      participantsNumber:
        course?.documentParticipantInfo?.length.toString() ?? "",
    }),
    [course],
  );

  const disableReminderButtonsByCompanyStatus = useMemo(
    () =>
      documentType === DocumentType.PRECONTRACT &&
      course?.companySignatureStatus !== DocumentCompanyStatus.VALIDATED,
    [course?.companySignatureStatus, documentType],
  );

  const disableMassReminderButton = useMemo(
    () => pendingManagementParticipants.length === 0,
    [pendingManagementParticipants.length],
  );

  const csvHeaders = useMemo(() => {
    const headers: {
      label: string;
      key: keyof ParsedCourseDetailsCSVInfo;
    }[] = [
      { label: "Nombre", key: "name" },
      { label: "RUT", key: "rut" },
      { label: "Correo electrónico", key: "email" },
      { label: "Teléfono", key: "phone" },
      { label: "Firma participante", key: "mainSignerStatus" },
    ];
    if (documentType === DocumentType.PRECONTRACT) {
      headers.push({ label: "Firma empresa", key: "secondarySignerStatus" });
    }
    return headers;
  }, [documentType]);

  const parsedExportableData: ParsedCourseDetailsCSVInfo[] = useMemo(() => {
    if (!course?.documentParticipantInfo) return [];
    return parseCourseDetailsInfoToCSV(course?.documentParticipantInfo);
  }, [course?.documentParticipantInfo]);

  /* CALLBACKS */
  /* PAGE CALLBACKS */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedParticipantsFilter = useCallback(
    debounce((text: string) => {
      if (text.length <= 2) {
        setParticipantsTableRawData(participantsData);
      }
      const fuseResult = map(fuse.search(text), "item");
      setParticipantsTableRawData(
        participantsData.filter((p) => map(fuseResult, "rut").includes(p.rut)),
      );
    }, DEBOUNCE_TIME),
    [setParticipantsTableRawData, participantsData, fuse],
  );

  const onClearIconClick = useCallback(() => {
    setSearchText("");
    setParticipantsTableRawData(participantsData);
  }, [setSearchText, setParticipantsTableRawData, participantsData]);

  const searchBarOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;
      setSearchText(text);
      if (text.length <= 2) {
        setParticipantsTableRawData(participantsData);
        return;
      }
      debouncedParticipantsFilter(event.target.value);
    },
    [debouncedParticipantsFilter, setSearchText, participantsData],
  );

  /* REMINDER DIALOG CALLBACKS */
  const onOpenMassiveReminderDialog = useCallback(() => {
    setMassiveReminderDialogOpen(true);
  }, [setMassiveReminderDialogOpen]);

  const onCloseMassiveReminderDialog = useCallback(() => {
    setMassiveReminderDialogOpen(false);
  }, [setMassiveReminderDialogOpen]);

  const sendMassiveReminder = useCallback(
    async (email: string | null) => {
      if (massiveReminderToResponsable && !email) return;
      const documentIds = map(pendingManagementParticipants, "id");
      const participants = map(pendingManagementParticipants, (participant) => {
        return {
          name: participant.participant.name,
          rut: participant.participant.rut,
          email: participant.participant.email,
          documentStatus: participant.document.status,
          comment:
            participant.document.status === DocumentStatus.CORRECTION_REQUESTED
              ? participant.lastCorrectionRequestComment
              : "No ha firmado el documento",
        };
      });

      toggleLoader(true, `${pageName} Sending massive reminder`);
      const massiveReminderSuccesful = massiveReminderToResponsable
        ? await sendInternalMassReminder(
            {
              emails: [email ?? ""],
              sencenet,
              documentType,
              participants,
            },
            { sencenet, documentType },
          )
        : await sendParticipantsReminders(
            { documentIds, documentType },
            { sencenet, documentType },
          );
      toggleLoader(false, `${pageName} Sending massive reminder`);
      if (!massiveReminderSuccesful) {
        showSnackbar("Algo falló al enviar el recordatorio masivo", "error");
        return;
      }
      setMassiveReminderDialogOpen(false);
      showSnackbar("Recordatorio masivo enviado exitosamente", "success");
    },
    [
      pendingManagementParticipants,
      toggleLoader,
      pageName,
      massiveReminderToResponsable,
      documentType,
      sencenet,
      showSnackbar,
    ],
  );

  const onReminderEditionFinished = useCallback(
    async (id: number, emailData: EmailForm) => {
      toggleLoader(true, `${pageName} Sending individual reminder`);
      try {
        const emails = parseMultipleEmails(emailData.email);

        const individualReminderSuccesful = await sendCustomReminders(
          {
            emailsData: [
              {
                message: emailData.message,
                emails,
                subject: emailData.subjectOption ?? EmailSubject.REQUEST_SIGN,
                documentId: id,
              },
            ],
            documentType,
          },
          { sencenet, documentType },
        );

        if (!individualReminderSuccesful) {
          throw new Error("mutation failed");
        }
        toggleLoader(false, `${pageName} Sending individual reminder`);
        showSnackbar("Recordatorio individual enviado exitosamente", "success");
      } catch (e) {
        toggleLoader(false, `${pageName} Sending individual reminder`);
        showSnackbar(
          "Algo falló al enviar el recordatorio individual",
          "error",
        );
        console.error(e);
      }
    },
    [toggleLoader, pageName, documentType, sencenet, showSnackbar],
  );

  const onReminderClick = useCallback(
    (id: number) => {
      const docParticipantData = course?.documentParticipantInfo.find(
        (p) => p.id === id,
      );

      if (!docParticipantData) return;

      const { canSendReminderToResponsable, canSendParticipantReminder } =
        getDocumentActions(docParticipantData.document);

      if (canSendReminderToResponsable) {
        openMessageDialog(
          getMessageDialogParams(
            course,
            docParticipantData,
            DocumentActions.SEND_REMINDER_TO_RESPONSABLE,
            documentType,
            route,
            onReminderEditionFinished,
            user,
          ),
        );
        return;
      }
       // Se deshabilita correo a participantes en DJP
       /*
      if (canSendParticipantReminder) {
        openMessageDialog(
          getMessageDialogParams(
            course,
            docParticipantData,
            DocumentActions.SEND_REMINDER_TO_SIGNER,
            documentType,
            route,
            onReminderEditionFinished,
            user,
          ),
        );
        return;
      }*/
    },
    [
      course,
      route,
      getDocumentActions,
      openMessageDialog,
      user,
      documentType,
      onReminderEditionFinished,
    ],
  );

  const getReminderButtonIconData = useCallback(
    (id: number): CellIconData | null => {
      const docParticipantData = course?.documentParticipantInfo.find(
        (p) => p.id === id,
      );
      if (!docParticipantData) return null;
      const { canSendParticipantReminder, canSendReminderToResponsable } =
        getDocumentActions(docParticipantData.document);

      const { hasMissingData } = docParticipantData.participant;
      const pendingCompanySignature = [
        DocumentCompanyStatus.PENDING,
        DocumentCompanyStatus.PRESIGNED,
      ].includes(docParticipantData.document.companyStatus);

      const disabled = hasMissingData || pendingCompanySignature; 

      if (canSendReminderToResponsable) {
        return {
          icon: <MailOutline />,
          tooltipLabel: "Enviar recordatorio al PDC",
        };
      }
      // Se deshabilita correo a participantes en DJP
      /*
      if (canSendParticipantReminder) {
        return {
          icon: <MailOutline />,
          tooltipLabel:
            hasMissingData && pendingCompanySignature
              ? "Este participante no puede firmar digitalmente debido a que la empresa aún no firma y además no posee todos los datos necesarios"
              : pendingCompanySignature
              ? "Este participante no puede firmar digitalmente debido a que la empresa aún no firma"
              : hasMissingData
              ? "Este participante no puede firmar digitalmente debido a que no posee todos los datos necesarios"
              : "Enviar recordatorio al participante",  
          disabled,
        };
      }*/
      return null;
    },
    [course, getDocumentActions],
  );

  /* FILE DIALOG CALLBACKS (VALIDATION, FORCED VALIDATION, UPLOAD) */
  const onCloseValidationDialog = useCallback(() => {
    closeValidationDialog();
  }, [closeValidationDialog]);

  const onCloseUploadDialog = useCallback(() => {
    closeUploadDialog();
  }, [closeUploadDialog]);

  const manuallyUploadFile = useCallback(
    async (
      file: File,
      documentParticipantInfo: DocumentParticipantInfo | null,
      callback: () => void,
    ) => {
      if (!documentParticipantInfo) return;
      toggleLoader(true, `${pageName} Uploading file`);
      const { id } = documentParticipantInfo;
      // Send file
      const wasUploaded = await uploadManualDocument(
        {
          documentId: id,
          file,
        },
        {
          sencenet,
          documentType,
        },
        documentType,
      );
      if (!wasUploaded) {
        toggleLoader(false, `${pageName} Uploading file`);
        showSnackbar("Algo falló al subir el documento", "error");
        return;
      }
      toggleLoader(false, `${pageName} Uploading file`);
      showSnackbar("Se ha subido el documento con éxito", "success");
      callback();
    },
    [toggleLoader, pageName, sencenet, documentType, showSnackbar],
  );

  const onForceDocumentValidationFinish = useCallback(
    async (id: number, emailData: EmailForm) => {
      toggleLoader(true, `${pageName} Forcing document validation`);
      const forcedValidationSuccesful = await forceDocumentValidation(
        {
          documentId: id,
          comment: emailData?.message,
        },
        { sencenet, documentType },
        documentType,
      );
      toggleLoader(false, `${pageName} Forcing document validation`);
      if (!forcedValidationSuccesful) {
        showSnackbar("Algo falló al realizar la validación forzada", "error");
        return;
      }
      showSnackbar("Validación forzada realizada exitosamente", "success");
    },
    [documentType, pageName, sencenet, showSnackbar, toggleLoader],
  );

  // Passed as prop to be used in ValidationDialog (document replacement)
  const openUploadDialogCallback = useCallback(
    (docParticipantData: DocumentParticipantInfo) => () => {
      openUploadDialog({
        common: {
          onFinish: (file, callback) =>
            manuallyUploadFile(file, docParticipantData, callback),
          onClose: onCloseUploadDialog,
          title: getUploadDialogTitle(docParticipantData, documentType),
        },
        courseData: dialogInfoData,
        documentExists: !!docParticipantData.document.url,
        stillOpenAt: route,
      });
      closeValidationDialog();
    },
    [
      closeValidationDialog,
      dialogInfoData,
      documentType,
      manuallyUploadFile,
      onCloseUploadDialog,
      openUploadDialog,
      route,
    ],
  );

  const onClickFile = useCallback(
    (id: number) => {
      if (!course) return;
      const docParticipantData = course.documentParticipantInfo.find(
        (p) => p.id === id,
      );
      if (!docParticipantData) return;
      const {
        participant: { name, rut },
        forcedValidationComment,
      } = docParticipantData;
      const {
        canValidate,
        canManualUpload,
        canView,
        canForceValidation,
        canViewForcedValidation,
        canReplace,
      } = getDocumentActions(docParticipantData.document);

      if (canManualUpload) {
        openUploadDialog({
          common: {
            onFinish: (file, callback) =>
              manuallyUploadFile(file, docParticipantData, callback),
            onClose: onCloseUploadDialog,
            title: getUploadDialogTitle(docParticipantData, documentType),
          },
          courseData: dialogInfoData,
          stillOpenAt: route,
          documentExists: !!docParticipantData.document.url,
        });
      } else if (canValidate || canView) {
        openValidationDialog({
          common: {
            onClose: onCloseValidationDialog,
            title: getValidationDialogTitle(
              docParticipantData,
              course,
              documentType,
            ),
          },
          course,
          headerCourseData: dialogInfoData,
          participant: docParticipantData?.participant,
          sencenet,
          stillOpenAt: route,
          document: docParticipantData.document,
          actionable: canValidate || canReplace,
          openUploadDialog: openUploadDialogCallback(docParticipantData),
        });
      } else if (canForceValidation) {
        openMessageDialog({
          title: `Validación forzada ${DocumentShortNames[documentType]} · RUT ${rut} · ${name}`,
          onlyCommentMode: true,
          stillOpenAt: route,
          onFinish: (emailData: EmailForm) =>
            onForceDocumentValidationFinish(id, emailData),
        });
      } else if (canViewForcedValidation) {
        openInformationDialog({
          title: `${DocumentShortNames[documentType]} validada forzadamente · RUT ${rut} · ${name}`,
          text:
            forcedValidationComment ??
            "No hay comentarios relacionados a la validación forzada",
          stillOpenAt: route,
        });
      }
    },
    [
      course,
      getDocumentActions,
      openUploadDialog,
      onCloseUploadDialog,
      documentType,
      dialogInfoData,
      manuallyUploadFile,
      openValidationDialog,
      onCloseValidationDialog,
      sencenet,
      route,
      openUploadDialogCallback,
      openMessageDialog,
      onForceDocumentValidationFinish,
      openInformationDialog,
    ],
  );

  const getFileButtonIconData = useCallback(
    (id: number): CellIconData | null => {
      const docParticipantData = course?.documentParticipantInfo.find(
        (p) => p.id === id,
      );
      if (!docParticipantData) return null;
      if (!canInteractDocument(docParticipantData.document)) return null;
      const { status } = docParticipantData.document;
      const availableDocActions = getDocumentActions(
        docParticipantData.document,
      );
      return getDocumentIconButtonData(status, availableDocActions);
    },
    [canInteractDocument, course?.documentParticipantInfo, getDocumentActions],
  );

  const enrichedColumnDefs: TableColumnData[] = useMemo(() => {
    // Filter columns by permissions
    const colDefs = columnDefs.filter(
      (colDef) => !colDef.permissions || isAllowedTo(colDef.permissions, false),
    );

    // Inject styles to icon button cols
    colDefs
      .filter((col) => col.cellType === TableCellType.ICON_BUTTON)
      .forEach((col) => {
        col.style = styles.tableIcons;
      });
    // Inject callbacks
    const reminderCol = colDefs.find((col) => col.attr === "sendReminder");
    if (reminderCol) {
      reminderCol.onClick = onReminderClick;
      reminderCol.getIconData = getReminderButtonIconData;
    }

    const fileCol = colDefs.find((col) => col.attr === "document");
    if (fileCol) {
      fileCol.onClick = onClickFile;
      fileCol.getIconData = getFileButtonIconData;
    }

    const statusCol = colDefs.find((col) => col.attr === "mainSignerStatus");
    if (statusCol) statusCol.onClick = onClickFile;

    return colDefs;
  }, [
    columnDefs,
    onClickFile,
    isAllowedTo,
    styles.tableIcons,
    onReminderClick,
    getReminderButtonIconData,
    getFileButtonIconData,
  ]);

  /* EFFECTS */
  useEffect(() => {
    setParticipantsTableRawData(participantsData);
  }, [participantsData, setParticipantsTableRawData]);

  useEffect(() => {
    toggleLoader(loading, `${pageName} Loading course details data`);
  }, [loading, pageName, toggleLoader]);
  return (
    <>
      {course && (
        <AccordionInfo
          course={course}
          documentType={documentType}
          route={route}
        />
      )}
      <Box sx={styles.filterDiv}>
        <Stack sx={styles.stack} direction="row" spacing={2}>
          <Typography variant={"h6"}>
            {DocumentShortNames[documentType]}
          </Typography>
          <Divider
            sx={styles.divider}
            variant="middle"
            flexItem
            orientation="vertical"
          />
          <Group sx={styles.participantsCount} />
          <Typography sx={styles.participantsCount} variant={"h6"}>{`${
            course?.documentParticipantInfo?.length ?? 0
          } participantes activos`}</Typography>
        </Stack>
        <Stack sx={styles.stack} direction="row" spacing={2}>
          <Restricted
            to={[  // Se deshabilita correo a participantes en DJP
              //Permission.CAN_SEND_PARTICIPANT_MAIL,
              Permission.CAN_SEND_RESPONSABLE_MAIL,
            ]}
            every={false}
          >
            <CustomButton
              startIcon={<MailOutline />}
              variant="outlined"
              onClick={onOpenMassiveReminderDialog}
              disabled={
                disableReminderButtonsByCompanyStatus ||
                disableMassReminderButton
              }
            >
              Recordatorio masivo
            </CustomButton>
          </Restricted>
          <DownloadButton
            data={parsedExportableData}
            headers={csvHeaders}
            filename={`lista-${DocumentShortNames[documentType]}-SN_${course?.sencenet}.csv`}
          />
          <SearchBar
            onChange={searchBarOnChange}
            value={searchText}
            InputProps={{
              endAdornment: searchText !== "" && (
                <IconButton size="small" onClick={onClearIconClick}>
                  <Clear />
                </IconButton>
              ),
            }}
            placeholder={"Buscar por nombre o rut del participante"}
          />
        </Stack>
      </Box>
      {participantsTableRawData && (
        <CustomTable
          rawData={participantsTableRawData}
          columnDefs={enrichedColumnDefs}
          disableSorting={searchText.length > 2}
        />
      )}
      <MassiveReminderDialog
        participants={pendingManagementParticipants ?? []}
        open={massiveReminderDialogOpen}
        onClose={onCloseMassiveReminderDialog}
        onFinish={sendMassiveReminder}
        toResponsable={massiveReminderToResponsable}
      />
    </>
  );
};

const generateStyles: CSSGenerator = (theme: Theme) => ({
  filterDiv: {
    display: "flex",
    justifyContent: "space-between",
    margin: "32px 0",
    alignItems: "center",
  },
  stack: {
    alignItems: "center",
    height: "fit-content",
  },
  button: {
    borderRadius: 18,
    height: 36,
  },
  participantsCount: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
  tableIcons: {
    color: theme.palette.common.black,
  },
});
